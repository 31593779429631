import { template as template_99b1501ade864b0cae2464833c3c8f75 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_99b1501ade864b0cae2464833c3c8f75(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
