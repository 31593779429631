import { template as template_5492ea1e9d7340af9b2d885503cd976a } from "@ember/template-compiler";
const FKLabel = template_5492ea1e9d7340af9b2d885503cd976a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
