import { template as template_1d4f464d3d5a4aeb8f44f4ebc5558045 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1d4f464d3d5a4aeb8f44f4ebc5558045(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
